import React, { Component } from 'react'
import { RoomContext } from "../context";

export default class FeaturedRooms extends Component {
    static contextType = RoomContext;
    
    // eslint-disable-next-line 
    render() {
        // eslint-disable-next-line 
        const {name,greeting} = this.context;
        
        return <div>
        </div>
        
    }
    
}
