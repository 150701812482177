import React from 'react'
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import {Link} from "react-router-dom";
import Services from "../components/Services";
import FeaturedRooms from "../components/FeaturedRooms";
export default function Home() {
    return (
      <>
    <Hero>
      <Banner title="Lilla Gylahuset" subtitle="Endast för speciellt utvalda">
      <Link to="/rooms" className="btn-primary">
        rummet
      </Link>
      </Banner>
    </Hero>
    <Services />
    <FeaturedRooms />
    </>
    );
}




