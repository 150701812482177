import React, { Component } from 'react'

export default class SingleRoom extends Component {
    state={
        
    }
    render() {
        return (
            <div>
            hej från singelrum   
            </div>
        )
    }
}

