import React from 'react'
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import {Link} from "react-router-dom";

const Rooms = () => {
    return <Hero hero="roomsHero">
      <Banner title="Rummet">
        <Link to="/" className="btn-primary">
          till förstasidan
        </Link>
        <Link to="./calendar" className="btn-primary">
          Boka
        </Link>
      </Banner>
    </Hero>

};

export default Rooms;


