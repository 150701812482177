import React, { Component } from 'react'
import {FaCocktail, FaShip, FaHamburger, FaBed} from "react-icons/fa";
import Title from "./Title";
export default class Services extends Component {
    state={
        services: [
            {
                icon:<FaCocktail />,
                title:"drycker",
                info: "Prata med Malte om du är törstig"
            },
            {
                icon:<FaShip />,
                title:"båttur",
                info: "Boka Malte för en tur på havet"
            },
            {
                icon:<FaHamburger />,
                title:"hungrisch",
                info: "Kolla kylen i huvudbyggnaden"
            },
            {
                icon:<FaBed />,
                title:"extra allt",
                info: "Säg till Minna om du behöver sängkläder/badhandduk"
            }
        ]
    };
    render() {
        return (
            <section className="services">

                <Title title="Boka till" />
                <div className="services-center">
                    {this.state.services.map((item,index) => {
                        return ( 
                        <article key={index} className="service">
                            <span>{item.icon}</span>
                            <h6>{item.title}</h6>
                            <p>{item.info}</p>
                        </article>
                        );
                
                    })}
                </div>
            </section>
        );
    }
}
